import Axios from 'axios'
import {useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {EducationLectureCompleteStatusData} from "@/network/apis/types";
import {getUserEducationLectureCompleteStatus} from "@/network";

interface SearchParams {
    eduNo: number;
    reloadCnt: number;
}

const emptyData: EducationLectureCompleteStatusData = {
    answerCnt: 0,
    examButtonYn: '',
    examCnt: 0,
    quizCnt: 0,
    surveyAnswerCnt: 0,
    surveyQuestionCnt: 0,
    weekCnt: 0,
    weekMasCnt: 0,
    passScore: 0,
    onlineScore: 0,
}

export function useFetchUserEducationLectureCompleteStatus(params: SearchParams) {
    const {eduNo, reloadCnt} = params
    const [detail, setDetail] = useState<EducationLectureCompleteStatusData>(emptyData)
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getUserEducationLectureCompleteStatus({eduNo}, { cancelToken: canceler.token })
                .then(({data}) => {
                    setDetail(data.result)
                })
                .catch(() => {
                    setDetail(emptyData)
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading, eduNo, reloadCnt])

    return {detail}
}
